import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      css={css`
        display: flex;
        justify-content: center;
        margin-top: 15vh;
      `}
    >
      <a
        href="https://www.youtube.com/watch?v=n6VjsvT6o3s"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          css={css`
            display: inline-block;
            padding: 2vh 20px;
            border: 2px solid #d4b5b5;
            border-radius: 3px;
            color: #d4b5b5;
            font-size: 20px;
            text-align: center !important;
          `}
        >
          Watch Takht al-Nagham Ensemble
        </div>
      </a>
    </div>
  </Layout>
)

export default IndexPage
